:root {
  --font-family: "Nunito";
  --btn-bg-color: "#5302ff";
  --body-bg-color: "#f5f5f5";
  --tag-bg-color: "rgba(83, 2, 255, 0.2)";
}

@font-face {
  font-family: "Nunito";
  src: url("../../assets/fonts/Nunito-Bold.woff2") format("woff2"),
    url("../../assets/fonts/Nunito-Bold.woff") format("woff");
  font-weight: bold;
}

@font-face {
  font-family: "Nunito";
  src: url("../../assets/fonts/Nunito-Regular.woff2") format("woff2"),
    url("../../assets/fonts/Nunito-Regular.woff") format("woff");
}

@font-face {
  font-family: "Nova Oval";
  src: url("../../assets/fonts/NovaOval.woff2") format("woff2"),
    url("../../assets/fonts/NovaOval.woff") format("woff");
}

body {
  // background-color: var(--body-bg-color) !important;
  background-color: #f5f5f5 !important;
  font-family: var(--font-family) !important;
}

body .container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0;

  @media (max-width: 1600px) {
    padding: 0 40px;
  }

  @media (max-width: 1024px) {
    padding: 0 20px;
  }

  @media (max-width: 991px) {
    padding: 0 15px;
  }
}

.ticket {
  background: #5302ff;
  border-radius: 10px;
  width: 100%;
  border: 0;
  padding: 15px;
  color: #fff;
  font-weight: 400;
  font-size: 18px;
  margin-top: 10px;
}

.ticket_fb {
  background: #3b5998;
  border-radius: 10px;
  width: 100%;
  border: 0;
  padding: 15px;
  color: #fff;
  font-weight: 400;
  font-size: 18px;
  margin-top: 10px;
}

.banner {
  padding: 30px 0px;
  background: #ffffff;

  .logo {
    text-align: center;

    img {
      @media (max-width: 767px) {
        max-width: 200px;
      }
    }
  }

  .sport-type {
    @media (max-width: 767px) {
      margin: 10px 0 0;
    }

    img {
      @media (max-width: 767px) {
        width: 100%;
      }
    }
  }

  .container {
    justify-content: space-between;
    align-items: center;

    @media (max-width: 767px) {
      flex-direction: column;
    }
  }
}

.event-category {
  border-width: 0.2px 0px 2px 0px;
  border-style: solid;
  border-color: #a3a3a3;
  background: #ffffff;
  padding: 10px 0;

  .category {
    align-items: center;

    a {
      letter-spacing: 0.2px;
      color: #151517;
      border-right: 1px solid #ddd;
      padding: 8px 20px;
      text-decoration: none;

      @media (max-width: 767px) {
        padding: 5px 10px 5px 10px;
      }

      &:last-child {
        border-right: 0;
      }

      &:first-child {
        padding-left: 0;
      }
    }

    .nav-item {
      a {
        border-right: 0;
        padding-left: 16px;
        display: block;
        text-decoration: none;
      }
    }
  }
}

.upcoming-event {
  padding: 70px 0;
  min-height: 576px;

  @media (max-width: 991px) {
    padding: 30px 0;
  }

  .events {
    h3.rec_no_found {
      position: absolute;
      top: 230px;
      left: 50%;
      transform: translatex(-50%);

      @media (max-width: 991px) {
        position: inherit;
        transform: none;
      }
    }
  }

  .event-box {
    display: flex;

    @media (max-width: 767px) {
      flex-direction: column;
    }

    .left {
      width: 74%;

      @media (max-width: 991px) {
        width: 67%;
      }

      @media (max-width: 767px) {
        width: 100%;
      }
    }

    .right {
      // max-width: 25%;
      flex: 1;
      margin: 96px 0 0 30px;

      @media (max-width: 991px) {
        margin: 100px 0 0 10px;
        // max-width: 33%;
      }

      @media (max-width: 767px) {
        margin: 10px 0 0 0px;
        // max-width: 100%;
      }
    }
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 767px) {
      flex-direction: column-reverse;
      align-items: flex-end;
      margin: 0 0 10px;
    }

    .t-c {
      text-align: left;

      @media (max-width: 767px) {
        width: 100%;
      }

      h2 {
        font-weight: 700;
        font-size: 34px;

        @media (max-width: 991px) {
          font-size: 24px;
        }
      }

      p {
        font-size: 20px;
        letter-spacing: 0.2px;
        color: #000000;
        opacity: 0.6;

        @media (max-width: 991px) {
          font-size: 16px;
        }
      }
    }

    .list-gride-view {
      @media (max-width: 767px) {
        display: flex;
      }

      display: flex;

      img {
        background: #ffffff;
        border: 1px solid #acacac;
        border-radius: 6px;
        padding: 5px;
        margin: 0 0 0 15px;
        cursor: pointer;

        @media (max-width: 767px) {
          margin: 0 0 0 5px;
        }

        &.active {
          background: rgba(83, 2, 255, 0.2);
          border-color: #5302ff;
        }
      }
    }
  }
}

.footer {
  background: #000000;
  padding: 50px 0;

  @media (max-width: 991px) {
    padding: 30px 0;
    margin-top: 28px;
  }

  .container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    @media (max-width: 991px) {
      display: flex;
      justify-content: space-between;
    }

    @media (max-width: 767px) {
      flex-wrap: wrap;
    }
  }

  .footer-menu {
    @media (max-width: 991px) {
      padding: 0 10px 0 0;
    }

    @media (max-width: 767px) {
      padding: 0 0px 20px 0;
      width: 100%;
    }

    &:last-child {
      @media (max-width: 991px) {
        padding: 0;
      }
    }

    a {
      display: block;
      font-weight: 400;
      font-size: 16px;
      margin: 20px 0 0;
      color: #ffffff;
      opacity: 0.6;
      text-decoration: none;

      @media (max-width: 991px) {
        margin: 10px 0 0;
        line-height: normal;
      }

      @media (max-width: 767px) {
        margin: 5px 0 0;
      }
    }

    h3 {
      font-weight: 700;
      font-size: 20px;
      color: #ffffff;
    }
  }
}

.events {
  .box {
    background: #ffffff;
    padding: 20px;
    border-bottom: 1px solid #ddd;

    @media (max-width: 1024px) {
      padding: 20px 10px;
    }

    .navigate {
      text-decoration: none;
      display: flex;

      @media (max-width: 767px) {
        flex-direction: column;
      }
    }

    .events-name {
      text-align: left;
      flex: 1;
      margin: 0 0 0 30px;

      @media (max-width: 991px) {
        margin: 0 0 0 15px;
      }

      @media (max-width: 767px) {
        margin: 12px 0 0 0px;
      }

      h3 {
        font-weight: 900;
        font-size: 20px;
        line-height: 1.2;
        color: #000000;
        margin: 0 0 10px;
        text-transform: capitalize;

        @media (max-width: 991px) {
          margin: 0px;
        }
      }

      h4 {
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        color: #000000;
        margin: 0 0 10px;
      }

      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 1.5;
        margin: 0 0 5px;

        @media (max-width: 991px) {
          font-size: 12px;
        }

        b {
          color: #5302ff;
          margin: 0 15px 0 0;
        }

        span {
          color: #000000;
          margin: 0 15px 0 0;
        }
      }

      .description {
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 1.5;
        color: #000000;
        align-items: end;
        margin-bottom: 5px;

        > *,
        h2,
        h1,
        h3,
        h4,
        h5,
        h6,
        p,
        span {
          font-size: 16px;
          margin-bottom: 0;
        }

        a {
          color: #5302ff;
        }
      }

      .event-location {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin: 12px 0 0;

        @media (max-width: 991px) {
          flex-direction: column;
          align-items: flex-start;
          margin: 0;
        }

        .post-chanrge {
          min-width: 140px;

          @media (max-width: 991px) {
            margin: 5px 0;
          }

          b {
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;
            color: #000000;
          }
        }

        .post-tag {
          flex: 1;
          display: flex;
          justify-content: flex-end;
          flex-wrap: wrap;

          @media (max-width: 991px) {
            justify-content: flex-start;
          }

          p {
            white-space: nowrap;
            background: rgba(83, 2, 255, 0.2);
            backdrop-filter: blur(5px);
            border-radius: 6px;
            padding: 5px 10px;
            margin: 0 0 5px 5px;
            color: #000000;
            // width: 80px;
            // overflow: auto;
            // text-align: center;

            @media (max-width: 991px) {
              margin: 0 5px 5px 0px;
            }
          }
        }
      }
    }

    .events-img {
      position: relative;
      // min-height: 200px;
      // height: 200px;
      display: flex;
      justify-content: center;
      border-radius: 10px;
      width: 379px;
      max-height: max-content;

      @media (max-width: 991px) {
        width: 100%;
        // min-height: 150px;
        // height: 150px;
      }

      // @media (max-width: 767px) {
      //   width: 100%;
      // }
    }

    .post-images {
      position: relative;
      flex: 1;
      // background: #f5f5f5;
      border-radius: 5px;

      > p {
        height: 100%;
      }

      .podcast {
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        color: #5302ff;
        margin: 0;
        position: absolute;
        bottom: 10px;
        left: 15px;
        background: #e0e0e0;
        border-radius: 6px;
        padding: 10px;
        height: auto;

        @media (max-width: 991px) {
          left: 10px;
          padding: 5px;
        }
      }

      img {
        width: 100%;
        height: auto;
        border-radius: 10px;
        margin: auto;
      }
    }

    .post-date {
      width: 50px;

      @media (max-width: 991px) {
        position: absolute;
        z-index: 999;
        left: 20px;
        top: 6px;
        width: auto;
      }

      p {
        font-weight: 900;
        font-size: 34px;
        line-height: 1.5;
        text-align: center;
        max-width: 200px;
        color: #000;
        margin: 0;

        b {
          display: block;
          font-size: 18px;
          opacity: 0.5;
        }
      }
    }
  }
}

.post-date {
  span {
    padding: 10px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 6px;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 900;
    line-height: normal;
    display: block;
    text-align: center;

    @media (max-width: 991px) {
      padding: 5px;
    }
  }

  b {
    display: block;
    font-size: 18px;
    color: #000000;
  }
}

.eventdetails {
  background-size: cover;
  background-position: center;
  padding: 50px 0;
  position: relative;

  @media (max-width: 1024px) {
    padding: 20px 0 10px;
  }

  .container {
    z-index: 99;
    position: relative;
    // min-height: 500px;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: 1024px) {
      // min-height: 450px;
    }

    @media (max-width: 767px) {
      // min-height: 350px;
    }
  }

  .event-grid-section {
    display: flex;
    width: 100%;

    @media (max-width: 950px) {
      display: block;

      .event-banner {
        width: 100%;
      }
    }
  }

  .logo {
    text-align: center;
    width: 100%;
    position: relative;
    margin: 0 0 40px;

    img {
      filter: invert(1);
    }

    a {
      position: absolute;
      left: 0;
      top: 5px;

      img {
        max-width: 30px;
        filter: invert(1);

        @media (max-width: 767px) {
          max-width: 20px;
        }
      }
    }
  }

  .d-flex {
    width: 100%;

    @media (max-width: 767px) {
      flex-direction: column;
    }
  }

  .event-banner {
    position: relative;
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // background-size: cover;
    background-size: 100% 100%;
    background-position: center;
    // padding: 10px 30px;
    padding: 0;
    background-repeat: no-repeat;
    box-shadow: -1px -5px 10px #bebebe30;
    // min-height: 400px;
    cursor: pointer;

    @media (max-width: 991px) {
      // width: 50%;
      // padding: 15px;
      // min-height: 300px;
    }

    @media (max-width: 767px) {
      width: 100%;
      margin: 0 0 20px;
    }

    .banner-content {
      position: absolute;
      padding: 10px 30px;
      width: 100%;
      bottom: 0;

      @media (max-width: 767px) {
        padding: 10px;
      }

      @media (max-width: 600px) {
        height: 100%;

        .post-content {
          position: absolute;
          bottom: 0;
        }
      }
    }

    .event-image {
      img {
        width: 100%;
      }
    }

    &::before {
      content: "";
      position: absolute;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
      width: 100%;
      height: 100%;
      left: 0;
      z-index: 0;
      max-height: 135px;
      bottom: 0;
    }

    .post-date {
      position: initial;
      max-width: 60px;
      min-width: 60px;
      z-index: 9;

      @media (max-width: 991px) {
        max-width: 50px;
        min-width: 50px;
      }

      @media (max-width: 600px) {
        max-width: 36px;
        min-width: 37px;
        position: absolute;
        top: 0;

        span {
          font-size: 10px;
          border-top-left-radius: 0 !important;
          border-top-right-radius: 0 !important;

          b {
            font-size: 11px;
          }
        }
      }

      span {
        @media (max-width: 991px) {
          padding: 5px;
        }
      }
    }

    h3 {
      font-weight: 800;
      font-size: 30px;
      line-height: 34px;
      color: #f3f3f3;
      margin: 15px 0;
      text-transform: capitalize;
      margin: 15px 0 0;
      z-index: 9;
      line-height: normal;

      @media (max-width: 767px) {
        font-size: 18px;
      }
    }

    p {
      font-weight: 500;
      font-size: 16px;
      line-height: normal;
      color: #f3f3f3;
      z-index: 9;
      margin: 0 0 5px;
    }
  }

  .event-picture {
    gap: 20px;
    cursor: pointer;
    flex: 1 1;
    margin: 0 0 0 25px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media (max-width: 1195px) {
      // gap: 20px;
    }

    @media (max-width: 950px) {
      margin-top: 2rem;
      margin-left: 0;
      flex-wrap: nowrap;
    }

    @media (max-width: 991px) {
      // margin: 0 0 0 15px;
      // gap: 10px;
    }

    @media (max-width: 767px) {
      margin: 0px;
      gap: 5px;
      min-height: 80px;
    }

    h3 {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      transform: translate(-50%, -50%);
      font-weight: 800;
      font-size: 30px;
      line-height: 34px;
      color: #000;
      text-align: center;
    }

    .picture-box {
      position: relative;
      // height: 190px;
      width: 48%;

      @media (max-width: 1400px) {
        width: 47%;
      }

      @media (max-width: 767px) {
        width: 49%;
      }

      img {
        width: 100%;
        object-fit: cover;
        object-position: center;
        // height: 190px;
        // height: 100%;
      }
    }
  }
}

.seacription {
  padding: 80px 0;

  @media (max-width: 991px) {
    padding: 30px 0;
  }

  .container {
    @media (max-width: 767px) {
      flex-direction: column;
    }
  }

  h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    color: #000000;
  }

  p {
    font-weight: 500;
    font-size: 18px;
    line-height: 34px;
    color: #000000;

    @media (max-width: 991px) {
      line-height: 1.5;
    }

    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 1.5;
      margin: 0 0 5px;
    }
  }

  .left {
    width: 75%;

    @media (max-width: 1440px) {
      width: 70%;
    }

    @media (max-width: 1024px) {
      width: 60%;
    }

    @media (max-width: 767px) {
      width: 100%;
    }

    // .ticket {
    //   max-width: 500px;
    // }

    .start-date {
      display: flex;
      justify-content: space-between;
      margin: 5px 0 20px;
      max-width: 600px;

      @media (max-width: 767px) {
        flex-direction: column;
      }

      .d-flex {
        justify-content: space-between;
        flex-direction: column;
        width: 50%;

        @media (max-width: 767px) {
          width: 100%;
          margin: 0 0 10px;
          flex-direction: row;
          justify-content: flex-start;
        }

        span {
          @media (max-width: 991px) {
            font-size: 14px;
          }

          @media (max-width: 767px) {
            margin: 0 10px 0 0;
          }
        }
      }
    }

    p {
      b {
        margin: 0 0 0 10px;
      }
    }

    .tags-details {
      margin: 40px 0;
      display: flex;
      align-items: center;

      @media (max-width: 991px) {
        flex-direction: column;
        align-items: flex-start;
        margin: 10px 0;
      }

      p {
        margin: 0 15px 5px 0;

        @media (max-width: 767px) {
          min-width: 78px;
          margin: 0 5px 5px 0;
        }
      }
    }

    .tags-tab {
      span {
        background: rgba(83, 2, 255, 0.2);
        border-radius: 6px;
        padding: 10px;
        margin: 0 5px 5px 0;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #000000;
        min-width: 100px;
        display: inline-block;
        text-align: center;
        // width: 80px;
        // overflow: auto;
        // text-align: center;
        // white-space: nowrap;

        @media (max-width: 767px) {
          min-width: auto;
        }
      }
    }
  }

  .riight {
    flex: 1;
    border-radius: 20px;
    margin: 0 0 0 30px;

    @media (max-width: 991px) {
      border-radius: 10px;
      margin: 0 0 0 10px;
    }

    @media (max-width: 767px) {
      margin: 20px 0 0;
    }
  }
}

.dash_pagination {
  .pagination {
    justify-content: center;
    margin: 50px 0 0;

    li {
      .page-link {
        background: #ffffff;
        border: 1px solid #cacaca;
        border-radius: 10px;
        font-weight: 700;
        font-size: 24px;
        line-height: 33px;
        color: #000000;
        margin: 0 5px;
        width: 42px;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: normal;
        cursor: pointer;

        @media (max-width: 767px) {
          width: 35px;
          height: 35px;
          font-size: 20px;
        }

        &:focus {
          outline: none;
          box-shadow: none;
        }
      }

      &:first-child,
      &:last-child {
        .page-link {
          background: #efefef;
          border-radius: 10px;
        }
      }

      &.active {
        .page-link {
          background: rgba(83, 2, 255, 0.2);
          border: 1px solid #5302ff;
          border-radius: 10px;
        }
      }
    }
  }
}

.contact-details {
  display: flex;
  align-items: center;
  // justify-content: space-between;
  margin: 0 0 20px;
  max-width: 610px;

  @media (max-width: 767px) {
    margin: 0 0 5px;
  }

  .name {
    width: 71%;

    @media (max-width: 767px) {
      width: 95%;
    }

    p {
      @media (max-width: 767px) {
        display: flex;
      }
    }
  }

  .icon {
    flex: 1;

    img {
      margin: 0 0 0 10px;
    }
  }

  p {
    margin: 0;
  }
}

.date_span {
  color: #000000;
  text-transform: capitalize;
  // font-size: 18px;
  margin: 0 0 5px;
}

.shop-now {
  margin: 0 0 15px;

  img {
    width: 100%;
  }
}

.news-letter {
  background: #ffffff;
  border-radius: 10px;
  padding: 15px;
  // margin: 0 0 20px;
  margin: 60px 0 20px;

  @media (max-width: 991px) {
    border-radius: 5px;
    padding: 5px;
  }

  // img {
  //   width: 100%;
  //   margin: 0 0 20px;
  // }

  .form-gropu {
    display: flex;

    // input {
    //   background: #f3f3f3;
    //   border-radius: 5px;
    //   border: 0;
    //   padding: 10px;
    //   margin: 0 10px 0 0;
    //   width: calc(100% - 100px);

    //   @media (max-width: 991px) {
    //     width: calc(100% - 70px);
    //   }

    //   &:focus {
    //     outline: none;
    //   }
    // }

    .sign-up {
      background: #5302ff;
      border-radius: 5px;
      border: 0;
      color: #fff;
      min-width: 105px;

      @media (max-width: 991px) {
        min-width: auto;
        font-size: 12px;
      }
    }
  }
}

.get-it-now {
  // min-height: 370px;
  background-repeat: no-repeat;
  border-radius: 10px;
  // padding: 15px;
  background-position: center;
  background-size: 100% 100%;
  position: relative;

  @media (max-width: 991px) {
    border-radius: 5px;
    padding: 10px 5px;
    // min-height: 250px;
  }

  @media (max-width: 991px) {
    text-align: center;
  }

  h2 {
    font-weight: 400;
    font-size: 28px;
    line-height: 34px;
    text-transform: capitalize;
    color: #ffffff;
    margin: 0 0 20px;
    font-family: "Nova Oval";

    @media (max-width: 991px) {
      font-size: 24px;
      margin: 0 0 10px;
    }
  }

  p {
    font-weight: 500;
    font-size: 30px;
    line-height: 39px;
    color: #ffffff;
    margin: 0;
    max-width: 300px;

    @media (max-width: 991px) {
      font-size: 20px;
      line-height: normal;
    }
  }

  .get-it-btn {
    background: #000;
    border-radius: 10px;
    border: 0;
    padding: 10px;
    min-width: 150px;
    position: absolute;
    bottom: -50px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    text-decoration: none;

    @media (max-width: 767px) {
      left: 75px;
      transform: translate(-50%, -50%);
      bottom: -72px;
    }

    img {
      margin: 0 0 0 5px;
      filter: invert(1);
    }
  }
}

.desc_event {
  color: #000 !important;
}

.feature_boader {
  width: 5px;
  // height: 100%;
  border-left: 3px solid #5302ff;
  margin: 0 5px 0 0;
}

.feature-tab {
  p {
    display: flex;
    align-items: center;
    z-index: 999;
    position: relative;
  }

  img {
    margin: 0 5px 0 0;
  }

  b {
    color: #fff;
  }
}

.event-selector {
  // width: 120px;
  cursor: pointer;
  padding: 5px;
  position: relative;

  &:focus {
    outline: 0;
    border: 0;
    box-shadow: none;
  }
}

.modal-backdrop {
  opacity: 0.7;
}

.image-modal {
  .modal-content {
    padding: 10px;

    text-align: center;

    img {
      width: fit-content;
      margin: auto;
      // height: 100%;
      // object-fit: cover;
      object-position: center;
      max-height: 92vh;
    }
  }

  .modal-header {
    position: absolute;
    top: 0;
    z-index: 9999;
    right: 0;
    background: #ffffff;
    display: none;
  }

  .carousel-control-prev {
    left: -50px;
    width: auto;
    opacity: 1;
  }

  .carousel-control-next {
    right: -50px;
    width: auto;
    opacity: 1;
  }
}

// .grad-ent {
//   background: linear-gradient(89.97deg, rgba(83, 2, 255, 0.1) 1.56%, rgba(83, 2, 255, 0) 75.64%);
//   backdrop-filter: blur(5px);
//   border-radius: 6px;
//   padding: 10px 0 2px;
//   margin: 0 0 10px;
// }
.social-link {
  padding: 0 0 0 30px;

  a {
    text-decoration: none;

    img {
      margin: 0 15px 0 0;
    }
  }
}

.date-time {
  display: flex;

  @media (max-width: 767px) {
    flex-direction: column;
    margin: 0 0 10px;
  }

  p {
    width: 35%;

    @media (max-width: 1024px) {
      width: auto;
    }

    @media (max-width: 767px) {
      margin: 0;
    }

    &:last-child {
      @media (max-width: 1024px) {
        margin-left: 20px;
      }

      @media (max-width: 767px) {
        margin-left: 0px;
      }
    }
  }
}

.event-box {
  position: relative;

  .select-box {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    background: #ffffff;
    border-radius: 10px;
    padding: 10px;
    min-width: 200px;
    justify-content: space-between;

    @media (max-width: 1600px) {
      right: 20px;
    }

    @media (max-width: 767px) {
      // position: relative;
      right: 13px;
      padding: 0;
      min-width: auto;
      top: -11px;
      .dropdown-toggle {
        &::after {
          display: none;
        }
      }
    }

    .filter_btn {
      margin: 0 10px 0 0;
      border-right: 2px solid #cfbfbf;
      padding: 0 10px 0 0;
      width: 32px;

      @media (max-width: 767px) {
        margin: 0;
        border-right: 0;
        padding: 0px;
        width: auto;
      }
    }

    .dropdown {
      flex: 1;

      @media (max-width: 767px) {
        display: none;
      }

      .dropdown-menu {
        left: auto !important;
        right: -11px !important;
        background: #ffffff;
        box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        border: 0;
        min-width: 214px;
      }

      .btn-success {
        background: #ffffff;
        border-radius: 10px;
        color: #999;
        border: 0;
      }

      .dropdown-menu {
        max-width: 214px;
      }

      .rbt-token-removeable {
        align-items: center;
      }

      .accordion {
        .accordion-item {
          border: 0;
          border-bottom: 1px solid #ddd;

          input[type="date"] {
            font-size: 11px;
          }

          button {
            padding: 12px;

            &:focus {
              outline: none;
              box-shadow: none;
            }
          }

          &:last-child {
            border-bottom: 0;
          }

          .accordion-body {
            padding: 12px;
          }

          .check_box {
            align-items: center;
            display: flex;
            justify-content: space-between;
            margin: 0 0 8px;
            padding-left: 30px;
            position: relative;
          }

          .check_box span {
            border-right: 0;
            width: 100%;
          }

          .check_box input[type="checkbox"] {
            display: none;
            position: relative;
          }

          .check_box .checkbox_check {
            border: 1px solid #5302ff;
            border-radius: 5px;
            height: 20px;
            left: 0;
            position: absolute;
            top: 2px;
            width: 20px;
          }

          .check_box input:checked ~ .checkbox_check {
            background-color: #5302ff;
            box-shadow: inset 0 0 0 1px #5302ff;
          }

          .checkbox_check:after {
            border: solid #fff;
            border-width: 0 2px 2px 0;
            content: "";
            display: none;
            height: 9px;
            left: 7px;
            position: absolute;
            top: 3px;
            -webkit-transform: rotate(40deg);
            transform: rotate(40deg);
            width: 4px;
          }

          .check_box input:checked ~ .checkbox_check:after {
            display: block;
          }

          .slide-pin input {
            -webkit-appearance: none;
            position: relative;
            width: 46px;
            height: 26px;
            background-color: #999;
            background-size: cover;
            border-radius: 50px;
            outline: none;
            transition: background-image 0.9s;
          }

          .slide-pin input:before {
            content: "";
            position: absolute;
            top: 3px;
            left: 0;
            height: 20px;
            width: 20px;
            border-radius: 50px;
            transition: all 0.9s;
            background-color: #fff;
          }

          .slide-pin input:checked {
            background-color: #5302ff;
            transition: background-image 0.9s;
          }

          .slide-pin input:checked:before {
            transform: translate(135%);
            transition: all 0.9s;
            background-color: #fff;
          }
        }
      }
    }

    &.showdropdown {
      @media (max-width: 767px) {
        display: block;
      }

      .dropdown {
        @media (max-width: 767px) {
          display: block;
        }
      }
    }
  }
}

.reset-btn {
  text-align: center;

  button {
    background: #5302ff;
    border: 1px solid #5302ff;
    border-radius: 10px;
    color: #fff;
    font-weight: 700;
    font-size: 16px;
    width: 100%;
    padding: 10px;
    max-width: 180px;
  }
}

.grade {
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    span {
      margin: 0px 0 0;
    }
  }

  span {
    margin: 30px 0 0;
  }

  .min,
  .max {
    width: 45%;

    select,
    input {
      background: #f3f3f3;
      border-radius: 6px;
      width: 100%;
      padding: 10px 5px;
      border: 0;

      &:focus {
        outline: none;
      }
    }
  }
}

.priceG {
  span {
    margin: 0;
  }
}

.min.selectedInput {
  width: 100% !important;
}

.disc-buttons {
  display: flex;
  width: 71%;
  gap: 11px;

  @media (max-width: 991px) {
    width: 100%;
    gap: 0;
    flex-direction: column;
    max-width: 300px;
  }

  a {
    width: 100%;

    button {
      img {
        margin-right: 10px;
      }
    }
  }
}

a.active {
  border-bottom: 1px solid #ffff;
}

.logout_btn {
  cursor: pointer;
}

.event-image {
  max-height: 419px;
  min-height: 419px;
  display: flex;
  justify-content: center;
  text-align: center;

  img {
    // height: 100%;
    height: auto;
    width: unset !important;
    margin: auto;
    max-height: 420px;
  }
}

.picture-box {
  position: relative;
  width: 48%;
  max-height: 197px;
  // text-align: center;

  img {
    height: 100%;
    width: unset !important;
  }
}

.desc-area {
  img {
    width: 100px;
  }
}

.night-life {
  background: #fff;
  padding: 5px;
  color: #151517;
  font-size: 18px;
  min-width: 200px;
  text-align: center;
  border-radius: 8px;
  margin: 0 0 0 20px;
  border: 1px solid #a3a3a3;
  font-weight: 700;
  cursor: pointer;

  @media (max-width: 767px) {
    font-size: 16px;
    min-width: auto;
  }

  &.nightlife-active {
    background: #000;
    color: #fff;
    cursor: pointer;
  }
}

.nightlifeCheck {
  margin-top: -14px !important;
}

.currency-code {
  position: absolute;
  left: 8px;
  top: 10px;
}
@media only screen and (max-width: 767px) {
  .showWeb {
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  .showMob {
    display: none;
  }
}
