.modal_body {
  padding: 30px;

  .big_icon {
    text-align: center;
    margin-bottom: 20px;

    img {
      width: 110px;
    }
  }
}

.modal-content {
  max-height: 95vh;
  overflow: auto;
  margin: 9px 0;
}

.modal_footerB {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;

  .modalbtn {
    min-width: 160px;
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }
  }

  .bg-trn_bnt {
    color: #5302ff;
    border: 1px solid #000000;
    background: transparent;
  }
}

.modal_body_seem {
  padding: 20px;
}

.modal-dialog {
  // max-width: 420px;
  margin: auto;
}

@media (max-width: 575px) {
  .modal_body {
    padding: 25px 10px;

    .big_icon img {
      width: 100px;
    }

    .cusbtn {
      font-size: 14px;
      min-height: 42px;
      padding: 7px 15px;
    }

    .modal_footerB .modalbtn {
      min-width: 120px;
    }
  }
}

.modal_body_content {
  form {
    margin-top: 30px;

    textarea {
      border: 1px solid #5302ff;
      border-radius: 4px;
      width: 100%;
      padding: 5px 11px;

      &::placeholder {
        font-size: 1rem;
      }
    }
  }
}

// ---------------------------------* croper
.croper-modal {
  .modal-body {
    padding: 0 0 15px;
  }

  .croper-body {
    max-height: 75vh;
    padding: 30px 30px 0;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    @media (max-height: 1000px) and (min-height: 500px) {
      max-height: 60vh;
    }

    @media (max-height: 500px) {
      max-height: 50vh;
    }
  }

  .modal-dialog {
    height: 100vh;
  }

  .modal-header {
    justify-content: center;
  }

  .croper-footer {
    justify-content: center;
    margin-top: 10px;
    gap: 20px;

    button {
      // padding: 0rem 5rem;
      width: 35%;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}
.delete-modal .modal-content {
  padding: 10px 30px 30px;
}

.primery-btn {
  background: #1065d1;
  border-radius: 3px;
  border: 0;
  color: #fff;
  padding: 10px;
  font-weight: 500;
  font-size: 22px;
  font-family: var(--heading-fonts);
  min-width: 150px;
  text-decoration: none;
  display: inline-block;
  text-align: center;

  @media (max-width: 991px) {
    padding: 5px;
    font-size: 20px;
    min-width: 120px;
  }

  &:hover {
    color: #fff;
  }
}

.modalPay {
  margin-top: 14px;
}
