:root {
  --theme-color: #5302ff;
}

.wrapper-container {
  display: flex;
  justify-content: center;
  align-items: center;

  .btxt {
    margin-bottom: 0;
  }

  .col-left {
    width: 50%;
    padding: 25px;
    height: 100vh;
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .col-right {
    width: 50%;

    img {
      height: 100vh;
      object-fit: cover;
    }
  }
}

.form_onboard {
  width: 500px;
  margin: auto;
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  padding: 30px 25px;
  background: #fff;

  .heading {
    color: #000;
    text-align: center;
    font-size: 24px;
    font-family: "Nunito";
    font-weight: 900;
  }

  .textp {
    text-align: center;
    font-size: 14px;
    font-family: "Nunito";
    font-weight: 300;
  }

  .form-area {
    margin-top: 30px;
  }

  label {
    color: #1a0e2c;
    font-size: 16px;
    font-family: "Nunito";
    font-weight: 400;
  }

  .cusbtn {
    font-size: 16px;
    color: #fff;
    background: #5302ff;
    border: 1px solid #5302ff;
    border-radius: 10px;
    text-align: center;
    height: 50px;
    font-family: "Nunito";
    font-weight: 700;
    transition: ease-in-out 0.5s;

    &:hover {
      background: #fff;
      color: #5302ff;
    }
  }

  .btxt {
    font-family: "Nunito";
    font-weight: 600;
  }
}

.inputControl {
  font-size: 16px;
  height: 50px;
  padding: 5px 20px;
  background: #f9f9f9;
  border: 1px solid #dfe4f4;
  border-radius: 10px;
  width: 100%;
  margin-top: 8px;
  transition: ease-in-out 0.3s;
  outline: none !important;
  font-family: "Nunito";
  font-weight: 500;

  &:focus {
    border: 1px solid #5302ff;
  }
}

.linkOn {
  color: #5302ff;
  font-family: "Nunito";
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
}

.multipleInput {
  .inputControl {
    margin-right: 15px;
    width: 50px !important;
    padding: 10px;
  }
}

.inputicon {
  position: absolute;
  right: 10px;
  top: 20px;
  cursor: pointer;
}

.inputAdd_icon {
  position: relative;

  .inputControl {
    padding-right: 40px;
  }
}

@media (max-width: 1199px) {
  .wrapper-container {
    .col-left {
      width: 65%;
    }

    .col-right {
      width: 35%;
    }
  }
}

@media (max-width: 991px) {
  .wrapper-container {
    .form_onboard {
      width: 400px;
    }
  }
}

@media (max-width: 767px) {
  .wrapper-container {
    .logo-area {
      img {
        width: 140px;
      }
    }

    .col-left {
      width: 100%;
      background-image: url("../../../public/assets/images/onboading_bg.png");
      background-size: cover;
      height: 100%;

      .login-container {
        box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.12);
        border-radius: 10px;
        padding: 30px 25px;
        background: #fff;

        .form_onboard {
          box-shadow: none;
          padding: 0;
        }
      }
    }

    .col-right {
      display: none;
    }
  }
}

@media (max-width: 480px) {
  .wrapper-container {
    .form_onboard {
      width: 100%;
    }

    .col-left {
      padding: 30px 10px;

      .login-container {
        padding: 20px 15px;
      }
    }
  }

  .multipleInput {
    .inputControl {
      margin-right: 10px;
      width: 46px !important;
      height: 46px;
      padding: 5px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.event-toggle-btn {
  .form-check-input {
    cursor: pointer;
    margin-top: 2px;
    position: relative;

    &:checked {
      background-color: var(--theme-color);
      border-color: var(--theme-color);
    }
  }

  .form-switch {
    .form-check-input {
      width: 41px;
      height: 21px;
    }
  }
}

.radiobtnCon_g {
  justify-content: end;

  .rdinp {
    width: 0;
    opacity: 0;
    height: 0;
  }

  .radiobtn_item {
    position: relative;
  }

  .rdchecked_icon {
    position: absolute;
    top: 29px;
    left: 5px;
    display: none;
    transition: ease-in-out 0.5s;
  }

  .rdinp:checked ~ .rbtnev {
    color: #5302ff;
    border: 1px solid #5302ff;
  }
  .rdinp:checked ~ .rbtnev1 {
    color: #999999;
    border: 1px solid #999999;
  }

  .rdinp:checked ~ .rdchecked_icon {
    display: inline-block;
  }

  .rbtnev1 {
    padding: 15px 20px;
    cursor: pointer;
    border: 1px solid #999999;
    border-radius: 10px;
    color: #999999;
    font-size: 14px;
    display: flex;
    align-items: center;
    word-spacing: 1px;
    transition: ease-in-out 0.5s;

    b {
      font-size: 18px;
      margin-right: 5px;
    }
  }
  .rbtnev {
    padding: 15px 20px;
    cursor: pointer;
    border: 1px solid #999999;
    border-radius: 10px;
    color: #999999;
    font-size: 14px;
    display: flex;
    align-items: center;
    word-spacing: 1px;
    transition: ease-in-out 0.5s;

    b {
      font-size: 18px;
      margin-right: 5px;
    }
  }
}

.form-switch {
  .form-check-input {
    &:checked {
      &:focus {
        background-image: url(../../../public/assets/images/white-circle.svg);
      }
    }

    &:focus {
      background-image: url(../../../public/assets/images/grey-circle.svg);
      border-color: #b3b3b3;
    }
  }
}

.search-box-fav {
  background: #ffffff;
  border-radius: 10px;
  padding: 6px 12px;
  min-width: 280px;

  img {
    margin: 0 5px 0 0;
    max-width: 16px;
  }

  input {
    border: 0;
    font-size: 14px;
  }
}

fieldset {
  border-color: transparent !important;
}

.text-ellipsis {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.primary-txt {
  color: #5302ff;
}

.pointer {
  cursor: pointer;
}

.ck-text-editor {
  .ck-editor__editable {
    max-height: 200px;
    height: 200px;
  }
}

.captcha-area_event {
  margin-bottom: 18px;
}

.template {
  max-width: 400px;
  margin: 0 auto;
}

.template p {
  text-align: center;
  font-weight: 600;
  margin: 20px 0;
}

.logoN {
  margin: 0 0 20px;
  padding: 0 0 20px;
  border-bottom: 1px solid #ddd;
  text-align: center;
}

.click-btn {
  color: #fff;
  padding: 10px;
  border: 0;
  min-width: 120px;
  background: #5302ff;
  border-radius: 8px;
}

.Subscribe {
  display: flex;
  align-items: center;
}

.template input {
  background: #f3f3f3;
  border: 0;
  padding: 10px;
  border-radius: 5px;
  margin: 0 10px 0 0;
  width: 100%;
}

.template input:focus {
  outline: none;
}

.file_upload_con {
  .clear-data img {
    position: absolute !important;
    top: 9% !important;
    right: -1% !important;
    width: 22px !important;
    background: #fff !important;
    border-radius: 100% !important;
  }
}
.btn-div {
  display: flex;
  gap: 15px;
}

.click-btn,
.upcoming-btn {
  background: #5302ff;
  border: 0;
  border-radius: 8px;
  color: #fff;
  padding: 10px;
}

.past-btn,
.upcoming-btn {
  min-width: 182px;
}
.past-btn {
  background: #fff;
  border: 1px solid #cacaca;
  border-radius: 8px;
  color: #000;
  padding: 10px;
}
