// spinner

.sweet-loading {
  background: #ffffff42;
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 999999;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 200px;
  }
}
