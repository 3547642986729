::-webkit-scrollbar {
    width: 4px;
    height: 3px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #ccc;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: #aaa;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #777;
}

.header {
    background: #151517;
    padding: 6px;
    position: relative;

    @media (max-width:767px) {
        padding: 6px 6px 6px 6px;
    }

    .navbar-toggler-icon {
        filter: invert(1);
    }

    .navbar {
        @media (max-width:991px) {
            position: inherit;
        }
    }

    .navbar-toggler {
        padding: 0;
    }

    .navbar-collapse {
        justify-content: space-between;

        @media (max-width:991px) {
            position: absolute;
            top: 53px;
            background: rgba(0, 0, 0, 0.75);
            padding: 12px 25px;
            width: 100%;
            left: 0px;
            z-index: 999;
        }

    }

    .menu-iten,
    .social {
        a {
            color: #fff;
            letter-spacing: 0.2px;
            font-size: 16px;
            padding: 0 !important;
            margin: 0 40px 0 0;
            text-transform: uppercase;
            text-decoration: none;

            @media (max-width:991px) {
                margin: 0 10px 0 0;
            }

            img {
                @media (max-width:991px) {
                    max-width: 28px;
                }
            }

            &:last-child {
                margin: 0 !important;
            }
        }
    }

    .social {
        flex-direction: row;
    }

}

.menu-iten {
    @media (max-width:991px) {
        align-items: flex-start;
    }

    a {
        @media (max-width:991px) {
            margin: 0 0 10px 0 !important;
        }
    }
}

.search-box {
    margin: 0 40px 0 0;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 6px 12px;
    min-width: 280px;

    @media (max-width:991px) {
        margin: 0;
    }

    @media (max-width:767px) {
        min-width: auto;
        // position: absolute;
        bottom: 10px;
        left: 0;
        right: 0;
        max-width: 250px;
        margin-left: auto;
    }

    img {
        margin: 0 5px 0 0;
        max-width: 16px;
    }

    input {
        border: 0;
        font-size: 14px;

        &:focus {
            outline: none;
        }
    }
}