body {
  margin: 0;
  padding: 0;
  background: #f1f0f0;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none !important;

  &:hover {
    text-decoration: none;
  }
}

ul,
ol {
  list-style: none;
}

button {
  border: none;
}

button:focus,
input:focus {
  outline: none;
}

.mb20 {
  margin-bottom: 20px;
}

img {
  max-width: 100%;
}