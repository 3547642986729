.heart_icon {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  background: transparent;
  padding: 0;
  margin: 0;
}

.formH {
  font-size: 28px;
  font-family: "Nunito";
  font-weight: 700;
}

.wrapper_content {
  background-color: #fafafc;
  border-radius: 10px;
  margin: auto;
  padding: 40px 30px;
  max-width: 1232px;

  .head1 {
    font-size: 28px;
    font-family: "Nunito";
    font-weight: 700;
  }

  .event-toggle-btn {
    .form-check-input {
      cursor: pointer;
      // border-color: #86b7fe;
      margin-top: 2px;
      position: relative;

      &:checked {
        background-color: var(--theme-color);
        border-color: var(--theme-color);

        // &:after {
        //     opacity: 0;
        // }
      }
    }

    .form-switch {
      .form-check-input {
        width: 41px;
        height: 21px;
      }
    }
  }

  .form-switch {
    .form-check-input {
      &:checked {
        &:focus {
          background-image: url("../../../public/assets/images/white-circle.svg");
        }
      }

      &:focus {
        background-image: url("../../../public/assets/images/grey-circle.svg");
        border-color: #b3b3b3;
      }
    }
  }

  .form_content {
    input {
      border: 1px solid #cacaca;
    }

    textarea {
      border: 1px solid #cacaca;
      outline: none !important;
    }

    select {
      border: 1px solid #cacaca;
    }

    .padd-right {
      padding-right: 20px;
    }

    .padd-left {
      padding-left: 20px;
    }
  }
}

.input_group {
  position: relative;
}

.input_group {
  position: relative;

  .clear-data {
    img {
      width: 17px;
      position: absolute;
      bottom: 13px;
      right: 34px;
    }
  }

  .rbt-input-multi.form-control {
    font-size: 16px;
    color: #2e2e2e;
    background-color: #f3f3f3 !important;
    // background: url("../../../public/assets/images/dropDown.svg") no-repeat right 10px center;
    appearance: none;
    border-radius: 10px;
    padding: 9px 15px;
    width: 100%;
    min-height: 46px;
    padding-right: 30px;
    border: 1px solid #cacaca;
  }

  .rbt-token {
    color: #2e2e2e;
  }

  .rbt-token {
    // background-color: #5302FF;
    // color: #fff;
  }

  .input_group .rbt-token-label {
    color: #fff;
    font-family: "Nunito";
    font-weight: 400;
    font-size: 14px;
  }

  .rbt-close-content {
    color: #fd1d1d;
    font-size: 24px;
    position: relative;
    top: -6px;
  }

  .rti--tag {
    // background-color: #5302FF;
    // color: #fff;
    padding-left: 10px;
    font-family: "Nunito";
    font-weight: 500;
  }

  .rti--tag button {
    color: #fd1d1d;
  }

  .rti--container {
    font-size: 16px;
    color: #2e2e2e;
    background: #f3f3f3;
    border-radius: 10px;
    padding: 0px 15px;
    width: 100%;
    min-height: 46px;
    border: 1px solid #cacaca;
  }

  .rti--input {
    width: 100%;
    max-width: 200px;
    border-radius: 5px;
    padding: 0 10px;
    font-family: "Nunito";
    font-weight: 500;

    min-height: 38px;
  }
}

label {
  font-size: 16px;
  -webkit-user-select: none;
  user-select: none;
  color: #1a0e2c;
  // font-family: "nunito-regular";
  margin-bottom: 7px;
}

.form_content input {
  border: 1px solid #cacaca;
}

.form_content textarea {
  border: 1px solid #cacaca;
}

.form_content select {
  border: 1px solid #cacaca;
}

.select_control {
  font-size: 16px;
  color: #2e2e2e;
  background: #f3f3f3;
  border: 1px solid #eeeeee;
  border-radius: 10px;
  padding: 9px 15px;
  width: 100%;
  height: 46px;
  font-family: "Nunito";
  font-weight: 500;
}

.select_icon-arrow {
  background-color: #f3f3f3 !important;
  // background: url("../../../public/assets/images/dropDown.svg") no-repeat right 10px center;
  padding-right: 30px;
  appearance: none;
  cursor: pointer;
}

.date_time_input {
  .input_control {
    cursor: pointer;
  }

  .MuiInputBase-formControl {
    border: 0;
  }

  .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root,
  .css-14lo706 {
    display: none !important;
    border: 0;
  }

  .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-color: transparent !important;

    &:hover,
    &:focus {
      box-shadow: none;
      border-color: transparent;
    }
  }

  .MuiInputBase-input {
    height: fit-content;
    width: 100% !important;
    padding: 10.5px 15px;
  }

  .MuiFormLabel-root {
    transform: translate(14px, 12px) scale(1);
  }
}

.textarea {
  font-size: 16px;
  color: #2e2e2e;
  background: #f3f3f3;
  border: 1px solid #eeeeee;
  border-radius: 10px;
  padding: 9px 15px;
  width: 100%;
  height: 200px;
  resize: none;
  // font-family: "nunito-medium";
}

.input_control,
.MuiInputBase-input {
  font-size: 16px;
  color: #2e2e2e;
  background: #f3f3f3;
  border: 1px solid #eeeeee;
  border-radius: 10px;
  padding: 9px 15px;
  width: 100%;
  height: 46px;
  font-family: "Nunito";
  font-weight: 500;
}

.location-in_icon {
  background-color: #f3f3f3 !important;
  background: url("../../../public/assets/images/map-pin.svg") no-repeat right 10px center;
  padding-right: 30px;
  cursor: pointer;
}

.cald_icon {
  background-color: #f3f3f3 !important;
  appearance: none;

  &::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    display: block;
    background: url("../../../public/assets/images/calendar.svg") no-repeat right center;
    width: 30px;
    height: 30px;
    border-width: thin;
    cursor: pointer;
  }
}

.MuiInputBase-input {
  background: url("../../../public/assets/images/calendar.svg") no-repeat right 10px center !important;
  appearance: none;
}

.time-picker {
  input {
    background: url("../../../public/assets/images/clock.png") no-repeat right 10px center !important;
    appearance: none;
    background-size: 18px 18px !important;
  }
}

.uploadbtn_file {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 250px;
  border: 1px dashed #cacaca;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;

  img {
    width: 100%;
  }
}

.subLabel {
  color: #6e6d6d;
  padding-left: 7px;
  font-size: 15px;
}

// ---------------------------- custom checkbox --------------------------------
.custom-checkbox {
  input[type="checkbox"] {
    position: relative;
    border: 2px solid #5302ff;
    border-radius: 2px;
    border-radius: 2px;
    background: none;
    cursor: pointer;
    line-height: 0;
    margin: 0 0.6em 0 0;
    outline: 0;
    padding: 0 !important;
    vertical-align: text-top;
    height: 16px;
    width: 16px;
    -webkit-appearance: none;

    &:hover {
      opacity: 1;
    }

    &:before {
      content: "";
      position: absolute;
      right: 49%;
      top: 50%;
      width: 5px;
      height: 9px;
      border: solid #5302ff00;
      border-width: 0 2px 2px 0;
      margin: 0px -1px 0px 0px;
      transform: rotate(45deg) translate(-61%, -55%);
      z-index: 2;
    }

    &:checked {
      opacity: 1;

      &:before {
        border: solid #5302ff;
        border-width: 0px 2px 2px 0px;
        opacity: 1;
      }
    }
  }

  label {
    cursor: pointer;
  }
}

.in_addicon {
  display: flex;
  align-items: center;
  color: #808080;
  font-size: 16px;
  background: #f3f3f3;
  border: 1px solid #cacaca;
  border-radius: 10px;
  width: 100%;
  padding-left: 12px;

  .input_control {
    border: none !important;
    padding-left: 10px;
  }
}

.threeinput_item {
  position: relative;

  .clear-data {
    img {
      bottom: 15px;
    }
  }
}

.MuiFormControl-root {
  width: 100%;
  background-color: #f3f3f3;
}

.cusbtn {
  font-size: 18px;
  padding: 10px 15px;
  background: #5302ff;
  border: 1px solid #cacaca;
  border-radius: 10px;
  color: #fff;
  transition: ease-in-out 0.5s;
  min-height: 48px;
  border: 1px solid #5302ff;
  font-family: "Nunito";
  font-weight: 700;
}

.upload_img_here {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  margin-top: 10px;

  .img_cont {
    position: relative;
    position: relative;
    height: 98px;
    width: 134px;
    text-align: center;
    background: #f3f3f3;
    border: 1px solid #ddd;
    border-radius: 4px;

    .uploadedimg {

      object-fit: cover;
      border-radius: 4px;
      max-height: 100%;
      min-height: 100%;
    }




    img {
      width: 100%;
    }
  }

  .crossbtn {
    position: absolute;
    width: 20px !important;
    height: 20px;
    border-radius: 50%;
    background: #5302ff;
    padding: 5px;
    cursor: pointer;
    right: -2px;
    top: -5px;
  }

  .uploadimg {
    max-width: 110px;
    max-height: 110px;
  }
}

@media (max-width: 991px) {
  .threeinput_fd {
    flex-direction: column;

    .threeinput_item {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@media (max-width: 767px) {
  .form_content {
    .padd-right {
      padding-right: 10px;
    }

    .padd-left {
      padding-left: 10px;
    }
  }
}

@media (max-width: 375px) {
  .upload_img_here {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }
}

sup {
  color: #fd1d1d;
  top: -0.5em;
}

.alert-msg {
  color: #fd1d1d;
  margin-top: 1px;
  font-size: 14px;
  margin-bottom: 0;
}